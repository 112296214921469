.App {
  text-align: center;
  font-family: Helvetica;
  font-weight: bold;
}

.label-hover {
  opacity: 0.5;
}

.label-hover:hover {
  padding: 15px 32px;
  font-weight: bold;
  opacity: 1;
}

.sold-hover {
  opacity: 0.7;
}

.sold-hover:hover {
  opacity: 9;
}

a {
	color: WHITE;
  }

  a:link {
	text-decoration: none;
  }
  
  a:visited {
	text-decoration: none;
  }
  
  a:hover {
	text-decoration: underline;
  }
  
  a:active {
	text-decoration: underline;
  }

.options {
  background-color: blue;
  text-align: center;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-family: Helvetica;
  font-weight: bold;
}
.options:hover {
  background-color: turquoise;

}


img {
  max-width: 100%;
  max-height: 100%;
}



.myButton {
	box-shadow:inset 0px 1px 0px 0px #fbafe3;
	background:linear-gradient(to bottom, #ff5bb0 5%, #ef027d 100%);
	background-color:#ff5bb0;
	border-radius:6px;
	border:1px solid #ee1eb5;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #c70067;
}
.myButton:hover {
	background:linear-gradient(to bottom, #ef027d 5%, #ff5bb0 100%);
	background-color:#ef027d;
}
.myButton:active {
	position:relative;
	top:1px;
}
.blueButton {
	box-shadow:inset 0px 1px 0px 0px #afecfb;
	background:linear-gradient(to bottom, #1144df 5%, #02d3ef 100%);
	background-color:lightblue;
	border-radius:6px;
	border:1px solid #4b1eee;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #0050c7;
}
.blueButton:hover {
	background:linear-gradient(to bottom, #ef027d 5%, #ff5bb0 100%);
	background-color:#ef027d;
}
.blueButton:active {
	position:relative;
	top:1px;
}

.form-style-6{
	font: 95% Arial, Helvetica, sans-serif;
  font-weight: bold;
	padding: 16px;
}
.form-style-6 h1{
	background: #43D1AF;
	padding: 20px 0;
	font-size: 140%;
	font-weight: 300;
	text-align: center;
	margin: -16px -16px 16px -16px;
}
.form-style-6 input[type="text"],
.form-style-6 input[type="email"],
.form-style-6 button,
.form-style-6 textarea,
.form-style-6 select 
{
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	-o-transition: all 0.30s ease-in-out;
	outline: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	background: #fff;
	margin-bottom: 4%;
	border: 1px solid #ccc;
	padding: 3%;
	color: #555;
	font: 95% Arial, Helvetica, sans-serif;
}
.form-style-6 input[type="text"]:focus,
.form-style-6 input[type="email"]:focus,
.form-style-6 textarea:focus,
.form-style-6 select:focus
{
	box-shadow: 0 0 5px rgb(218, 149, 22);
	padding: 3%;
	border: 1px solid #43D1AF;
}

.form-style-6 input[type="submit"],
.form-style-6 input[type="button"]{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	padding: 3%;
	background: #43D1AF;
	border-bottom: 2px solid #30C29E;
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;	
	color: #fff;
}
.form-style-6 input[type="submit"]:hover,
.form-style-6 input[type="button"]:hover{
	background: #2EBC99;
}

.form-style-6 input[type="submit"]:hover,
.form-style-6 button{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	padding: 3%;
	background: #43D1AF;
	border-bottom: 2px solid #30C29E;
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;	
	color: #fff;
}
.form-style-6 button:hover{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	padding: 3%;
	background: rgb(245, 143, 9);
	border-bottom: 2px solid rgb(240, 30, 40);
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;	
	color: #fff;
}

